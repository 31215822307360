<template>
  <div class="grid">
    <div class="col-12 xl:col-12">
      <div class="card">
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <ProjectInfo />
        <!-- INFO BAR ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <BodyHeader />
        <!-- BODY HEADER ----------------------------------------------------------------------------------------------------------------------------------->
        <!-- Accordion Tab ----------------------------------------------------------------------------------------------------------------------------------->
        <div class="grid" style="padding-top: 20px; justify-content: center">
          <div v-if="loading === true">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
          </div>
          <div
            v-else
            class="col-12 xl:col-12 sm:col-12 md:col-12 .col-12"
            style="text-align: center"
          >
            <ChartError
              :data="changes.description.chartData"
              :description="changes.description"
              :filterType="'basic'"
              :chartType="'pathogenic'"
              @filter="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectInfo from "@/components/ProjectInfo.vue";
import BodyHeader from "@/components/Pathogenic/BodyHeader.vue";
import ChartError from "@/components/Generic/ChartError.vue";
import { useProjectsStore } from "@/store/projects";
import axios from "axios";

export default {
  data() {
    return {
      projectsStore: useProjectsStore(),
      i: 1,
      changes: [],
      selectedType: null,
      loading: true,
      displayModal: false,
    };
  },
  computed: {
    project() {
      var projectId = this.$route.params.projectId;
      return this.projectsStore.project(projectId);
    },
  },
  mounted() {
    //this.getData({});
    this.loading = false
    this.changes = {
    "description": {
        "organism": "Patógenos",
        "chartData": {
            "Patógenos": {
                "bacterias": [
                ],
                "fungus": [
                    {
                        "type": "Fusarium",
                        "avgH": 6.08,
                        "avgT": 10.38,
                        "avgLowT ": "",
                        "avgHighT ": ""
                    },
                    {
                        "type": "Fusarium oxysporum",
                        "avgH": 5.08,
                        "avgT": 7.38,
                        "avgLowT ": "",
                        "avgHighT ": ""
                    },
                    {
                        "type": "Setophoma terrestres",
                        "avgH": 2.58,
                        "avgT": 5.58,
                        "avgLowT ": "",
                        "avgHighT ": ""
                    }
                ]
            }
        }
    }
}
  },
  beforeUnmount() {},
  created() {},
  components: {
    ProjectInfo: ProjectInfo,
    BodyHeader: BodyHeader,
    ChartError: ChartError,
  },
  methods: {
    async getData({ taxonomico = 0, area = 0, estagio = 0 }) {
      try {
        this.loading = true;
        const response = await axios
          .get(
            `https://dev.b4adashboard.com/api/b4proof/resultados/pathogens/94/1/1/${taxonomico}/${area}/${estagio}`
          )
          .then((response) => response.data);

        this.changes = response;
        console.log(this.changes);
        console.log(this.loading);
      } catch (e) {
        alert(e.message || "Ocorreu um erro");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
